
import { defineComponent, PropType, Ref, ref } from 'vue'
import FormItem from '@/components/formItem/index.vue'
import Data from '@/model/common/Data'
import { FormItemListType } from '@/types/comm'
interface Rules {
  [key: string]: Data[]
}
interface ModalData {
  title: string
  form: Data
  formItemList: FormItemListType<Data>
  rules: Rules
  visible: boolean
  okText?: string
  cancelText?: string
  Ref?: unknown
  labelCol: unknown
  width?: string
  ok?: (formRef?: Ref) => void
  openBefore?: () => void
  cancel?: (formRef?: Ref) => void
}
export default defineComponent({
  props: {
    modalData: {
      type: Object as PropType<ModalData>,
      required: true,
    },
    visible: {
      type: Boolean,
    },
  },
  components: { FormItem },
  emits: ['onUpdateForm', 'update:visible'],
  setup(props, { emit }) {
    //输入框修改
    const onUpdateForm = (val: unknown, prop: string | number) => {
      emit('onUpdateForm', val, prop)
      console.log(props.modalData.form)
    }
    //表单实例
    const formRef = ref()
    //关闭事件 传递表单实例
    const onCancel = () => {
      formRef.value?.resetFields()
      emit('update:visible', false)
      //传递表单Ref
      if (props.modalData?.cancel) props.modalData?.cancel(formRef)
    }
    //提交事件 传递表单实例
    const handleOk = () => {
      if (props.modalData?.ok) props.modalData?.ok(formRef)
    }
    return { onUpdateForm, onCancel, formRef, handleOk }
  },
})
