
import { defineComponent, toRefs, computed, reactive, Ref, ref, watch, nextTick } from 'vue'
import listLayout from '@/components/listLayout/listLayout.vue'
import { onBeforeRouteUpdate, useRoute } from 'vue-router'
import { message } from 'ant-design-vue'
import { useStore } from 'vuex'
import yTable from '@/components/yTable/index.vue'
import editButton from '@/components/editButton/index.vue'
import FormItem from '@/components/formItem/index.vue'
import { FormItemListType } from '@/types/comm'
import {
  AddDeptMember,
  DeleteBottomDeptMember,
  InfoDeptMember,
  PageDeptMember,
  UpdateDeptMember,
} from '@/axios/api/personnel'
import { PersonnelClass, ReqPersonnelClass } from '@/model/authorityManagement/personnel'
import Data from '@/model/common/Data'
import { debounce } from 'lodash'
import { getSuperiorDept } from '../departmentInformation/common'
import { DeptClass } from '@/model/authorityManagement/dept'
import modalForm from '@/components/modalForm/index.vue'
import { deleteModal } from '@/utils/deleteModal'
import Drawer from '@/components/Drawer/Drawer.vue'
import { ColumnsTypeData } from '@/components/Drawer/Drawer'
export default defineComponent({
  components: {
    listLayout,
    yTable,
    FormItem,
    editButton,
    modalForm,
    Drawer,
  },
  setup() {
    onBeforeRouteUpdate((to, from) => {
      console.log(from.name, pageData.selectFrom)
      store.commit('setRegForm', [from.name, pageData.selectFrom])
    })
    /**
     * @description: 获取筛选后可展示表头列表
     * @param { Array<ColumnsTypeData> } data 当前所有表头列表
     * @return {*}
     */
    const updateColumns = (value: Array<ColumnsTypeData>) => {
      pageData.loading = true
      pageData.columns = value
      nextTick(() => {
        pageData.loading = false
      })
    }
    //获取路由
    let route = useRoute()
    //获取store
    const store = useStore()
    const buttonObj = store.getters.getButtonObj

    const pageData = reactive({
      isShowDrawer: false, //列设置弹框展示控制
      selectFrom: new ReqPersonnelClass(),
      dataSource: [] as PersonnelClass[],
      columns: [
        {
          title: '序号',
          dataIndex: 'index',
          width: 400,
          key: 'index',
          resizable: true,
          customRender: ({ index }: { index: number }) => index + 1,
        },
        {
          title: '姓名',
          dataIndex: 'name',
          width: 400,
          key: 'name',
          resizable: true,
        },
        {
          title: '工号',
          dataIndex: 'code',
          width: 130,
          key: 'code',
          resizable: true,
        },
        {
          title: '所属部门',
          dataIndex: 'deptName',
          width: 180,
          key: 'deptName',
          resizable: true,
        },
        {
          title: '职位类型',
          dataIndex: 'isConcurrently',
          width: 95,
          key: 'isConcurrently',
          resizable: true,
          statusObj: {
            additionalPost: '兼职',
            notConcurrently: '不兼职',
          },
        },
        {
          title: '操作',
          dataIndex: 'operation',
          key: 'operation',
          width: 200,
          fixed: 'right',
          resizable: true,
        },
      ] as ColumnsTypeData[],
      selectedRowKeys: [] as string[],
      loading: false,
      formItemList: [
        {
          type: 'subInput',
          prop: 'name',
          placeholder: '请输入姓名',
          label: '姓名',
        },
        {
          type: 'subInput',
          prop: 'deptName',
          placeholder: '请选择所属部门',
          label: '所属部门:',
        },
      ] as FormItemListType<PersonnelClass>,
      modalData: {
        title: '新增',
        form: {} as PersonnelClass,
        formItemList: [
          {
            type: 'subInput',
            prop: 'name',
            placeholder: '请输入人员姓名',
            label: '人员姓名',
          },
          {
            type: 'subInput',
            prop: 'code',
            placeholder: '请输入人员工号',
            label: '人员工号',
          },
          {
            type: 'slot',
            prop: 'dept',
            placeholder: '请输入上级部门',
            label: '上级部门',
            optionList: [],
          },
          {
            type: 'subSelect',
            prop: 'isConcurrently',
            placeholder: '请输入是否兼职',
            label: '是否兼职',
            optionList: [
              { label: '是', value: 'additionalPost' },
              { label: '否', value: 'notConcurrently' },
            ],
          },
          {
            type: 'slot',
            prop: 'additionalPost1',
            placeholder: '请输入兼职部门1',
            label: '兼职部门1',
            optionList: [],
          },
          {
            type: 'slot',
            prop: 'additionalPost2',
            placeholder: '请输入兼职部门2',
            label: '兼职部门2',
            optionList: [],
          },
          {
            type: 'slot',
            prop: 'additionalPost3',
            placeholder: '请输入兼职部门3',
            label: '兼职部门3',
            optionList: [],
          },
        ] as FormItemListType<Data>,
        rules: {
          name: [{ required: true, message: '人员姓名不可为空', trigger: 'blur' }],
          code: [{ required: true, message: '人员工号不可为空', trigger: 'blur' }],
          isConcurrently: [{ required: true, message: '是否兼职不可为空', trigger: 'change' }],
        },
        visible: false,
        labelCol: { style: { width: '100px' } },
        width: '25%',
        ok: (formRef?: Ref) => {
          formRef?.value
            .validate()
            .then(() => {
              let api = pageData.modalData.title === '新增' ? AddDeptMember : UpdateDeptMember
              api(pageData.modalData.form)
                .then(() => {
                  pageData.modalData.visible = false
                  initeList()
                  message.success('成功')
                })
                .catch((err) => {
                  message.error(err)
                })
            })
            .catch((err: unknown) => {
              console.log(err)
            })
        },
      },
    })

    if (store.state.regForm[route.name as string] !== undefined) {
      pageData.selectFrom = JSON.parse(JSON.stringify(store.state.regForm[route.name as string]))
    }
    console.log(pageData.selectFrom)
    //获取表格信息
    const initeList = () => {
      pageData.loading = true
      PageDeptMember(pageData.selectFrom)
        .then((res) => {
          pageData.dataSource = []
          if (res.data) {
            Object.assign(pageData.dataSource, res.data)
          }
          pageData.selectFrom.currentPage = res.currentPage as number
          pageData.selectFrom.pageSize = res.pageSize
          pageData.selectFrom.dataCount = res.dataCount
          pageData.loading = false
        })
        .catch((err) => {
          pageData.loading = false
          message.error(err)
        })
    }
    //重置数据
    const selectProduct = () => {
      pageData.selectFrom.currentPage = 1
      pageData.selectFrom.pageSize = 10
      initeList()
    }
    //查询数据
    selectProduct()
    //传入table的页面配置
    const currentConfigure = computed(() => {
      let page = {
        pageSize: pageData.selectFrom.pageSize,
        currentPage: pageData.selectFrom.currentPage,
        total: pageData.selectFrom.dataCount,
        onChange(page: number, pageSize: number) {
          pageData.selectFrom.pageSize = pageSize
          pageData.selectFrom.currentPage = page
          initeList()
        },
        onShowSizeChange(current: number, size: number) {
          pageData.selectFrom.pageSize = size
          pageData.selectFrom.currentPage = current
          initeList()
        },
      }
      return page
    })

    //搜索
    const search = () => {
      selectProduct()
    }

    //重置
    const reset = () => {
      pageData.selectFrom.data = new PersonnelClass()
      selectProduct()
    }

    // 全选事件
    // const checkedChange = (checked: boolean) => {
    //   pageData.selectedRowKeys = checked
    //     ? (pageData.dataSource.map((item) => item.id) as string[]) || []
    //     : []
    // }

    // 跳转详情
    const goDetail = (record: PersonnelClass) => {
      // if (record.informationType === 'url') {
      //   window.open(record.url)
      // }
      console.log(record)
    }

    const fetching = ref(false)
    const deptList: Ref<DeptClass[]> = ref([])
    // 远程搜索部门名称
    const deptSearch = debounce((val: string) => {
      getSuperiorDept(val, deptList, fetching)
    }, 300)

    // 打开新增
    const add = () => {
      console.log('addadd')
      pageData.modalData.form = {
        isConcurrently: 'notConcurrently',
      } as PersonnelClass
      pageData.modalData.title = '新增'
      pageData.modalData.visible = true
    }

    // 打开修改
    const updateMember = (id: string) => {
      InfoDeptMember(id)
        .then((res) => {
          pageData.modalData.title = '修改'
          pageData.modalData.form = res
          deptList.value = []
          if (res.deptName) {
            deptList.value.push({
              deptName: res.deptName,
              id: res.dept,
            } as DeptClass)
          }
          if (res.additionalPost1Name) {
            deptList.value.push({
              deptName: res.additionalPost1Name,
              id: res.additionalPost1,
            } as DeptClass)
          }
          if (res.additionalPost2Name) {
            deptList.value.push({
              deptName: res.additionalPost2Name,
              id: res.additionalPost2,
            } as DeptClass)
          }
          if (res.additionalPost3Name) {
            deptList.value.push({
              deptName: res.additionalPost3Name,
              id: res.additionalPost3,
            } as DeptClass)
          }

          pageData.modalData.visible = true
        })
        .catch((err) => {
          message.error(err)
        })
    }

    // 删除
    const deleteList = (id: string) => {
      const ok = () => {
        DeleteBottomDeptMember(id)
          .then(() => {
            message.success('删除成功')
            initeList()
          })
          .catch((err) => {
            message.error(err)
          })
      }
      const onCancel = () => {
        console.log('Cancel')
      }
      deleteModal(ok, onCancel, '删除')
    }

    watch(
      () => pageData.modalData.form.isConcurrently,
      (newVal) => {
        if (newVal === 'notConcurrently') {
          pageData.modalData.form.additionalPost1 = undefined
          pageData.modalData.form.additionalPost2 = undefined
          pageData.modalData.form.additionalPost3 = undefined
        }
      }
    )
    return {
      updateMember,
      fetching,
      deptList,
      deptSearch,
      add,
      goDetail,
      ...toRefs(pageData),
      search,
      reset,
      buttonObj,
      currentConfigure,
      selectProduct,
      deleteList,
      // checkedChange,
      updateColumns,
    }
  },
})
