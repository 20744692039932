/*
 * @Descripttion:
 * @version:
 * @Author: congsir
 * @Date: 2023-04-21 15:56:09
 * @LastEditors:
 * @LastEditTime: 2023-04-21 15:58:39
 */
/**
 * 部门信息
 */
import { ReqPage } from '@/model/common/reqPage'
export class PersonnelClass {
  id: string | undefined // "string //主键"
  sortId: string | undefined // 序号
  dept: string | undefined // 所属部门
  deptName: string | undefined //  部门名称
  name: string | undefined // 名称
  code: string | undefined // 工号
  job: string | undefined // 职位;member:成员，admin:管理员
  isConcurrently: string | undefined //  	是否兼职；additionalPost:兼职  notConcurrently:不兼职
  additionalPost1: string | undefined //  	兼职1
  additionalPost2: string | undefined //  	兼职2
  additionalPost3: string | undefined //  	兼职3
  additionalPost1Name: string | undefined //  	兼职1
  additionalPost2Name: string | undefined //  	兼职2
  additionalPost3Name: string | undefined //  	兼职3
  createdBy: string | undefined // 创建人
  createdTime: string | undefined //创建时间
  updatedBy: string | undefined // 更新人
  updatedTime: string | undefined // 	更新时间
  isDeleted: string | undefined // 0是否删除;undeleted：未删除 deleted：已删除
}
export class ReqPersonnelClass extends ReqPage {
  data: PersonnelClass
  constructor() {
    super()
    this.data = new PersonnelClass()
  }
}

export interface RegPersonnelList extends ReqPage {
  data: PersonnelClass[]
}

export interface DeleteMemberDeptData {
  deptId: string //部门id
  memberId: string //员工id
}
