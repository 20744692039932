import { PageDept } from '@/axios/api/dept'
import { PageDeptMember } from '@/axios/api/personnel'
import { DeptClass, ReqDeptClass } from '@/model/authorityManagement/dept'
import { PersonnelClass, ReqPersonnelClass } from '@/model/authorityManagement/personnel'
import { message } from 'ant-design-vue'
import { Ref } from 'vue'

// 选择器获取部门分页
export const getSuperiorDept = (
  val: string,
  list: Ref<DeptClass[]>,
  fetching: Ref<boolean>
): void => {
  if (!val || val.length < 2) return
  fetching.value = true
  const data = new ReqDeptClass()
  data.data.deptName = val
  Object.assign(data, { noLoading: true })
  PageDept(data)
    .then((res) => {
      list.value = res.data
    })
    .catch((err) => {
      message.error(err)
    })
    .finally(() => {
      fetching.value = false
    })
}

// 选择器获取人员分页
export const getSuperiorDeptMember = (
  val: string,
  list: Ref<PersonnelClass[]>,
  fetching: Ref<boolean>
): void => {
  if (!val || val.length < 2) return
  fetching.value = true
  const data = new ReqPersonnelClass()
  data.data.name = val
  Object.assign(data, { noLoading: true })
  PageDeptMember(data)
    .then((res) => {
      list.value = res.data
    })
    .catch((err) => {
      message.error(err)
    })
    .finally(() => {
      fetching.value = false
    })
}
