import {
  PersonnelClass,
  RegPersonnelList,
  ReqPersonnelClass,
  DeleteMemberDeptData,
} from '@/model/authorityManagement/personnel'
import { $axios } from '../config/axios'
/**
 * @description:获取成员分页
 * @param {PersonnelClass} data
 * @return {*}
 * @see:http://wiki.agile.dgyiheda.com/wiki/kb/agile126?pageCode=19746
 */
export const PageDeptMember = (data: ReqPersonnelClass): Promise<RegPersonnelList> => {
  return $axios.post('api/bcic/dept/member/page', data)
}

/**
 * @description:新增成员
 * @param {PersonnelClass} data
 * @return {*}
 * @see:http://wiki.agile.dgyiheda.com/wiki/kb/agile126?pageCode=19747
 */
export const AddDeptMember = (data: PersonnelClass): Promise<unknown> => {
  return $axios.post('api/bcic/dept/member/add', data)
}

/**
 * @description:去除部门绑定人员
 * @param {string[]} data
 * @return {*}
 * @see:http://wiki.agile.dgyiheda.com/wiki/kb/agile126?pageCode=19748
 */
export const DeleteDeptMember = (data: DeleteMemberDeptData): Promise<unknown> => {
  return $axios.post('api/bcic/dept/member/dept/delete', data)
}

/**
 * @description:根据id修改员工信息
 * @param {PersonnelClass} data
 * @return {*}
 * @see:http://wiki.agile.dgyiheda.com/wiki/kb/agile126?pageCode=19752
 */
export const UpdateDeptMember = (data: PersonnelClass): Promise<unknown> => {
  return $axios.post('api/bcic/dept/member/update', data)
}

/**
 * @description:根据员工id获取员工信息
 * @param {string} data
 * @return {*}
 * @see:http://wiki.agile.dgyiheda.com/wiki/kb/agile126?pageCode=19750
 */
export const InfoDeptMember = (data: string): Promise<PersonnelClass> => {
  return $axios.get('api/bcic/dept/member/info', { params: { id: data } })
}

/**
 * @description:根据id删除员工信息
 * @param {string} id
 * @return {*}
 * @see:http://wiki.agile.dgyiheda.com/wiki/kb/agile126?pageCode=19751
 */
export const DeleteBottomDeptMember = (id: string): Promise<unknown> => {
  return $axios.delete('api/bcic/dept/member/delete', { params: { id } })
}
