import { DeptClass, RegDeptList, ReqDeptClass } from '@/model/authorityManagement/dept'
import { $axios } from '../config/axios'
/**
 * @description:获取部门信息分页
 * @param {ReqDeptClass} data
 * @return {*}
 * @see:http://wiki.agile.dgyiheda.com/wiki/kb/agile126?pageCode=19741
 */
export const PageDept = (data: ReqDeptClass): Promise<RegDeptList> => {
  return $axios.post('api/bcic/dept/page', data)
}

/**
 * @description:创建新部门
 * @param {DeptClass} data
 * @return {*}
 * @see:http://wiki.agile.dgyiheda.com/wiki/kb/agile126?pageCode=19740
 */
export const AddDept = (data: DeptClass): Promise<unknown> => {
  return $axios.post('api/bcic/dept/create', data)
}

/**
 * @description:根据部门id删除部门信息
 * @param {string[]} data
 * @return {*}
 * @see:http://wiki.agile.dgyiheda.com/wiki/kb/agile126?pageCode=19744
 */
export const DeleteDept = (id: string): Promise<unknown> => {
  return $axios.delete(`api/bcic/dept/delete?id=${id}`)
}

/**
 * @description:更新部门信息
 * @param {DeptClass} data
 * @return {*}
 * @see:http://wiki.agile.dgyiheda.com/wiki/kb/agile126?pageCode=19743
 */
export const UpdateDept = (data: DeptClass): Promise<unknown> => {
  return $axios.post('api/bcic/dept/update', data)
}

/**
 * @description:根据id获取部门详情信息
 * @param {string} data
 * @return {*}
 * @see:http://wiki.agile.dgyiheda.com/wiki/kb/agile126?pageCode=19742
 */
export const InfoDept = (data: string): Promise<DeptClass> => {
  return $axios.get('api/bcic/dept/info', { params: { id: data } })
}

/**
 * @description:根据id移除下级部门
 * @param {string} id
 * @return {*}
 * @see:http://wiki.agile.dgyiheda.com/wiki/kb/agile126?pageCode=19745
 */
export const DeleteBottomDept = (id: string): Promise<unknown> => {
  return $axios.delete('api/bcic/dept/bottom/delete', { params: { id } })
}
