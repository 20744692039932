/*
 * @Descripttion:
 * @version:
 * @Author: congsir
 * @Date: 2023-04-21 15:56:09
 * @LastEditors:
 * @LastEditTime: 2023-04-21 15:58:39
 */
/**
 * 部门信息
 */
import { ReqPage } from '@/model/common/reqPage'
export class DeptClass {
  id: string | undefined // "string //主键"
  sortId: string | undefined // 序号
  deptCode: string | undefined // 部门编号
  deptName: string | undefined //  部门名称
  adminName: string | undefined // 部门管理员名称
  adminId: string | undefined // 部门管理员id
  adminCode: string | undefined // 部门管理员编号
  superiorDeptCode: string | undefined //  	上级部门编号
  superiorDept: string | undefined //  	上级部门id
  superiorDeptName: string | undefined //  	上级部门名称
  createdBy: string | undefined // 创建人
  createdTime: string | undefined //创建时间
  updatedBy: string | undefined // 更新人
  updatedTime: string | undefined // 	更新时间
  detail: string | undefined // 详情
  isDeleted: string | undefined // 0是否删除;undeleted：未删除 deleted：已删除
}
export class ReqDeptClass extends ReqPage {
  data: DeptClass
  constructor() {
    super()
    this.data = new DeptClass()
  }
}

export interface RegDeptList extends ReqPage {
  data: DeptClass[]
}
